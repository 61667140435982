import React from 'react'
import { testimonials } from '../../constants/constants'
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'

//react icons import
import { BiSolidQuoteLeft, BiSolidQuoteRight } from 'react-icons/bi'

const Testimonials = () => {
    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3,
            slidesToSlide: 1
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2,
            slidesToSlide: 1
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
            slidesToSlide: 1
        }
    };

    return (
        <div className="py-5 my-5 bg-light position-relative overflow-hidden">
            <figure className="position-absolute bottom-0 start-0 mb-n5 ms-n5 d-sm-block">
                <svg width="285px" height="272px">
                    <path style={{ fill: '#112A41' }} className="opacity-4" d="M142.500,-0.000 C221.200,-0.000 285.000,60.889 285.000,136.000 C285.000,211.111 221.200,272.000 142.500,272.000 C63.799,272.000 -0.000,211.111 -0.000,136.000 C-0.000,60.889 63.799,-0.000 142.500,-0.000 Z"></path>
                </svg>
            </figure>
            <div className="w-100 text-center mb-5">
                <h1 className="fw-bold display-5">Student Testimonials</h1>
                <p className="text-textGray fs-5">The pathway to <b>excellence, Innovation, Professionalism </b> and <b>Entrepreneurship</b></p>
            </div>
            <div className="container">
                <Carousel
                    className="py-3 px-2"
                    responsive={responsive}
                    infinite={true}
                    autoPlay={true}
                    autoPlaySpeed={5000}
                    renderButtonGroupOutside={true}
                >
                    {
                        testimonials.map((testimonial, i) => {
                            return (
                                <div key={i} className="text-center p-3 bg-white shadow-sm rounded-3 me-3">
                                    <img style={{ height: "70px", width: "70px" }} className="rounded-circle" src={testimonial.image} alt={testimonial.name} />
                                    <h3>{testimonial.name}</h3>
                                    <blockquote>
                                        <p style={{ fontSize: "18px" }} className="">
                                            <BiSolidQuoteLeft className="me-2 fs-5" />{testimonial.quote}<BiSolidQuoteRight className="ms-2 fs-5" />
                                        </p>
                                    </blockquote>
                                </div>
                            )
                        })
                    }
                </Carousel>
            </div>
        </div>
    )
}

export default Testimonials