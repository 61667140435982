import React from 'react'
import { useSelector } from 'react-redux'

const Ourpartners = () => {
  const partners = useSelector((state) => state.appData.partners);

  return (
    <div className="container w-100 d-flex flex-column align-items-center my-4">
        <h1 className="display-4 mb-4 fw-semibold">Our Partners</h1>
        <div className="w-100 d-flex flex-column flex-lg-row justify-content-center gap-5 align-items-center">
            {
                partners.map(partner => (
                    <div key={partner.name} className="d-flex flex-column align-items-center flex-wrap rounded-2 shadow p-3">
                        <img src={partner.image} alt={partner.name} style={{height: "100px", width: "100px"}} />
                        <div style={{height: "5px", width: "100px"}} className="rounded-5 bg-actualBlue mt-3"></div>
                        <p className="text-black fs-4 text-capitalize mt-3 fw-semibold">{partner.name}</p>
                    </div>
                ))
            }
        </div>
    </div>
  )
}

export default Ourpartners