import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

i18n.use(LanguageDetector)
    .use(initReactI18next)
    .init({
        debug: true,
        fallbackLng: 'en',
        interpolation: {
            escapeValue: false,
        },
        resources: {
            en: {
                translation: {
                    landingPage: {
                        header: {
                            links: {
                                Home: "Home",
                                About: "About",
                                Programs: "Programs",
                                Faculties: "Faculties",
                                Contact: "Contact"
                            },
                            apply: "Apply"
                        }
                    }
                }
            },

            fr: {
                translation: {
                    landingPage: {
                        header: {
                            links: {
                                Home: "Acceuil",
                                About: "A propos",
                                Programs: "Programmes",
                                Faculties: "Ecoles",
                                Contact: "Contacte"
                            },
                            apply: "S'enregistrer"
                        }
                    }
                }
            }
        }
    })

export default i18n;