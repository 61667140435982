import { useState } from "react";
import { BsArrowRight, BsDot } from "react-icons/bs";
import { useSelector } from "react-redux";
import "../../styles/custom.scss"
import { AiOutlineArrowDown, AiOutlineArrowUp } from "react-icons/ai";

const Schools = () => {
    const faculties = useSelector((state) => state.appData.faculties);
    const [expandCourses, setExpandCourses] = useState(false);

    function onCourseClickBtn() {
        setExpandCourses(!expandCourses);
    }

    return (
        <div className="mb-5">
            <div className="w-100 text-center mb-2">
                <h1 className="fw-bold display-5">Our Schools</h1>
                <p className="text-textGray fs-5">The pathway to <b>excellence, Innovation, Professionalism </b> and <b>Entrepreneurship</b></p>
            </div>
            <div style={{maxHeight: `${ expandCourses ? "100%" : "1000px" }`}} className="px-5 w-100 pt-3 position-relative d-flex flex-column gap-5 overflow-hidden">
                {
                    faculties.map((faculty, i) => {
                        let courses = faculty.courses.split("&");

                        return (
                            <div key={i} style={{backgroundColor: `${faculty.bgColor}`}} className={`w-100 h-auto d-flex flex-wrap rounded-5 justify-content-center align-items-center flex-column shadow-lg border border-dark ${ i % 2 === 0 ? "me-lg-5 flex-lg-row-reverse": "ms-lg-5 flex-lg-row"}`}>
                                <div className="col-12 col-lg-6 h-100 p-2">
                                    <img src={faculty.image} className={`h-100 w-100 rounded-5`} />
                                </div>
                                <div className={`col-12 col-lg-6 p-3`}>
                                    <h1 className="display-5 fw-semibold mb-3 mb-lg-5">{faculty.name}</h1>
                                    <div className={`w-100`}>
                                        {
                                            i % 2 === 0 ? 
                                                courses.map((course, key) =>(                                                                                                   
                                                    <div key={key} className={`d-flex align-items-center`}>
                                                        <p style={{ fontSize: "14px" }} className="mb-1 fw-medium fst-italic"><BsArrowRight className="fs-4 me-3 text-primary fw-bold" />{course}</p>
                                                    </div>
                                                ))
                                            :
                                                <div className="w-100 d-flex gap-1 flex-wrap">
                                                    {
                                                        courses.map((course, key) => (
                                                            <p key={key} className="px-3 py-1 fw-bold bg-black text-white rounded-2">
                                                            {course}
                                                            </p>
                                                        ))
                                                    }
                                                </div>                                                
                                        }

                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
                <div style={{height: "300px"}} className="w-100 position-absolute bottom-0 start-0 d-flex justify-content-center align-items-end linear-transparency">
                    <p onClick={onCourseClickBtn} className="btn btn-primary rounded-pill p-2 border-transparentBlue border-5">
                        {
                            expandCourses ? <AiOutlineArrowUp className="fs-4"  /> : <AiOutlineArrowDown className="fs-4" />
                        }
                    </p>
                </div>
            </div>
        </div>
    )
}

export default Schools