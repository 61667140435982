import IntroCarousel from "../components/home/IntroCarousel"
import TbuIntro from "../components/home/TbuIntro"
import Contact from "../components/home/Contact"
import PeopleCount from "../components/home/PeopleCount"
import Testimonials from "../components/home/Testimonials"
import StudentBenefits from "../components/home/StudentBenefits"
import OurTeam from "../components/home/OurTeam"
import StudyCampus from "../components/home/StudyCampus"
import Schools from "../components/home/Schools"
import Ourpartners from "../components/home/Ourpartners"

const Home = () => {
    return (
        <div>
            <IntroCarousel />
            <div className="py-5 d-flex flex-column align-items-center">
                <TbuIntro />
                <StudentBenefits />
                <Schools />
                <StudyCampus />
                <OurTeam />
                <PeopleCount />
                <Contact />
            </div>
            <Testimonials />
            <Ourpartners />
        </div>
    )
}

export default Home