import React, { useEffect } from 'react'
import { brainsUniversity } from '../assets/assets'
import { footerLinks } from '../constants/constants'

// icons import
import { BiLogoFacebook, BiLogoInstagram, BiLogoTwitter, BiLogoTiktok } from 'react-icons/bi'

//react router imports
import { HashLink } from 'react-router-hash-link'

//translation imports
import { useTranslation } from 'react-i18next'

const Footer = ({ language, setLanguage }) => {
    const { i18n } = useTranslation();

    useEffect(() => {
        i18n.changeLanguage(language);
    }, [language, i18n])

    return (
        <div className="pt-5 mt-5 bg-light">
            <div className="container">
                <div className="row g-4">
                    {/* footer section 1 */}
                    <div className="col-lg-3">
                        <img style={{ width: "250px", height: "50px" }} src={brainsUniversity} className="object-fit-cover " alt='' />
                        <p className="fs-5 my-2 text-textGray">
                        We value honesty, moral integrity, and personal responsibility, fostering trust and ensuring ethical systems in all processes.
                        </p>
                        <ul className="list-inline mb-0 mt-3">
                            <li className="list-inline-item"> <a className="btn btn-white btn-sm shadow px-2 fs-2 fw-bold me-2 text-primary" href="/"><BiLogoFacebook /></a> </li>
                            <li className="list-inline-item"> <a className="btn btn-white btn-sm shadow px-2 fs-2 fw-bold me-2 text-danger" href="/"><BiLogoInstagram /></a> </li>
                            <li className="list-inline-item"> <a className="btn btn-white btn-sm shadow px-2 fs-2 fw-bold me-2 text-actualBlue" href="/"><BiLogoTwitter /></a> </li>
                            <li className="list-inline-item"> <a className="btn btn-white btn-sm shadow px-2 fs-2 fw-bold me-2" href="/"><BiLogoTiktok /></a> </li>
                        </ul>
                    </div>

                    {/* footer section 2 */}
                    <div className="col-lg-5">
                        <div className="row justify-content-lg-center">
                            <div className="col-6 col-md-4">
                                <h5 className="fw-bold mb-2 mb-md-4 fs-3">Links</h5>
                                <div className="d-flex flex-column align-items-start justify-content-center gap-2">
                                    {
                                        footerLinks.links.map(link => {
                                            return <HashLink
                                                key={link.name}
                                                to={link.to}
                                                className="text-decoration-none text-textGray fs-5 fw-medium"
                                            >
                                                {link.name}
                                            </HashLink>
                                        })
                                    }
                                </div>
                            </div>
                            <div className="col-6 col-md-4">
                                <h5 className="fw-bold mb-2 mb-md-4 fs-3">Faculties</h5>
                                <div className="d-flex flex-column align-items-start justify-content-center gap-2">
                                    {
                                        footerLinks.schools.map(link => {
                                            return <HashLink
                                                key={link.name}
                                                to={link.to}
                                                className="text-decoration-none text-textGray fs-5 fw-medium"
                                            >
                                                {link.name}
                                            </HashLink>
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* footer section 3 */}
                    <div className="col-lg-4">
                        <h5 className="fw-bold mb-2 mb-md-4 fs-3">Contact</h5>
                        <div className="d-flex flex-column align-items-start justify-content-center gap-1">
                            <p className="mb-0 text-textGray fw-medium">Number: <span className="fw-bold">(+237)656-964-666/(+237)696-516-021</span></p>
                            <p className="mb-0 text-textGray fw-medium">Email: <span className="fw-bold">thebrainsuniversity@gmail.com</span></p>
                            <p className="mb-0 text-black fw-bold">Dschang Campus: <span className="fw-medium text-textGray">Radio YEMBA</span></p>
                            <p className="mb-0 text-black fw-bold">Nkambe Campus: <span className="fw-medium text-textGray">Binju</span></p>
                            <div className="w-100">
                                <p className="fw-bold mb-0">Douala Campuses</p>
                                <ul className="ml-3">
                                    <li className="mb-0 text-textGray fw-medium"><span className="fw-bold">BP Cite:</span> Entree Camrail</li>
                                    <li className="mb-0 text-textGray fw-medium"><span className="fw-bold">Bonaberi:</span> Echangeur Fokou</li>
                                    <li className="mb-0 text-textGray fw-medium"><span className="fw-bold">Bonamoussadi:</span> Tradex parcours Vita</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Bottom footer */}
                <hr className="mt-5" />
                <div className="py-3">
                    <div className="container">
                        <div className="d-flex flex-column flex-md-row justify-content-center justify-content-md-between align-items-center">
                            <p className="text-textGray fw-medium">Copyrights &copy;2023 thebrainsuniversity. All rights reserved</p>
                            <select
                                style={{ width: "150px" }}
                                className="form-select shadow-none"
                                defaultValue="en"
                                onChange={(e) => {
                                    setLanguage(e.target.value)
                                }}
                            >
                                <option value="en">English</option>
                                <option value="fr">French</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Footer