import React from 'react'
import { introIMG, introIMG2, introIMG3 } from '../../assets/assets'
import Carousel from 'react-bootstrap/Carousel';

const Testimonials = () => {
    const carouselInfo = [
        {
            img: introIMG,
            title: "Comfortable lecture halls"
        },
        {
            img: introIMG2,
            title: "Equipped Laboratories"
        },
        {
            img: introIMG3,
            title: "Comfortable environment for education"
        }
    ]

    return (
        <div style={{ width: "100%" }} className=" d-flex flex-column justify-content-center align-content-center">
            <div className="w-100 text-center mb-3">
                <h1 className="fw-bold display-5">Study Campus</h1>
                <p className="text-textGray fs-5">The pathway to <b>excellence, Innovation, Professionalism </b> and <b>Entrepreneurship</b></p>
            </div>
            <div className="w-100 d-flex justify-content-center">
                <Carousel indicators={false} className=" w-100">
                    {
                        carouselInfo.map((item, i) => {
                            return (
                                <Carousel.Item key={i} className="position-relative" style={{ height: "500px" }}>
                                    <p className="position-absolute top-0 start-50 translate-middle-x bg-gold text-black text-center fw-bold px-md-5 px-2 py-1 py-md-2 fs-6 z-1">{item.title}</p>
                                    <img src={item.img} alt="" className="h-100 w-100 object-fit-cover" />
                                </Carousel.Item>
                            )

                        })
                    }

                </Carousel>
            </div>
        </div>
    )
}

export default Testimonials