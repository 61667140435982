import React from 'react'
import { focusPng } from '../../assets/assets'
import { BsArrowRight } from 'react-icons/bs'

const FocusObjectives = () => {
    return (
        <div className="d-flex flex-column align-items-center justify-content-center ">
            <div className="w-100 container row align-items-center my-5 py-5">
                <div style={{ paddingLeft: "200px" }} className="col-12 col-md-6 px-4 ps-md-5">
                    <h1 className="display-5 fw-medium">Our Focus/Objectives</h1>
                    <p className="mb-2">
                        Constant innovation is the need of the hour”. Innovation is the specific instrument of entrepreneurship. Our University brims with exceptional Brains that turn academic potentials into compelling societal relevance. Our students are groomed to be disruptive thinkers, innovative and entrepreneurial professionals
                    </p>
                    <p className="mb-2">
                        <b>Our Objectives:</b>
                    </p>
                    <div>
                        <div className="d-flex align-items-center">
                            <p style={{ fontSize: "14px" }} className="mb-1 fw-medium fst-italic"><BsArrowRight className="fs-4 me-3 text-primary fw-bold" />To provide for our students a conducive environment and Resources for the development of effective and virile professionals for nation building</p>
                        </div>
                        <div className="d-flex align-items-center">
                            <p style={{ fontSize: "14px" }} className="mb-1 fw-medium fst-italic"><BsArrowRight className="fs-4 me-3 text-primary fw-bold" />To become the pole of excellence in Cameroon and Africa through innovative learning strategies and creativity.</p>
                        </div>
                        <div className="d-flex align-items-center">
                            <p style={{ fontSize: "14px" }} className="mb-1 fw-medium fst-italic"><BsArrowRight className="fs-4 me-3 text-primary fw-bold" />To replace unemployment and career stagnation of graduates with creative ingenuity through well packaged programs, hands-on skills and practical training.</p>
                        </div>
                        <div className="d-flex align-items-center">
                            <p style={{ fontSize: "14px" }} className="mb-1 fw-medium fst-italic"><BsArrowRight className="fs-4 me-3 text-primary fw-bold" />To establish itself as a hub for partnership with local and international universities</p>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-md-6">
                    <img src={focusPng} alt="our mission" className="w-100 object-fit-cover" />
                </div>
            </div>
        </div>
    )
}

export default FocusObjectives