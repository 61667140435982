import React from 'react';
import { studentAbout1 } from '../../assets/assets';
import { BsArrowRight } from 'react-icons/bs';

const OurMision = () => {
    return (
        <div className="d-flex flex-column align-items-center justify-content-center ">
            <div className="w-100 container row align-items-center justify-content-center my-5" id="ourMission">
                <div className="col-12 col-md-6">
                    <img src={studentAbout1} alt="our mission" className="w-100 object-fit-cover rounded-5" />
                </div>
                <div className="col-12 col-md-6">
                    <h1 className="display-3 fw-medium">Our Mission</h1>
                    <p className="mb-2">
                        The Mission of the Brains University is to provide its students with an integral and holistic quality training that combines academic and professional training with hands-on skills that will enable them to compete and become prosperous.
                    </p>
                    <p className="mb-2">
                        Graduates, irrespective of race, gender, political and religious affiliation, will possess the following qualities:
                    </p>
                    <div>
                        <div className="d-flex align-items-center">
                            <p style={{ fontSize: "14px" }} className="mb-1 fw-medium fst-italic"><BsArrowRight className="fs-4 me-3 text-primary fw-bold" />Team player</p>
                        </div>
                        <div className="d-flex align-items-center">
                            <p style={{ fontSize: "14px" }} className="mb-1 fw-medium fst-italic"><BsArrowRight className="fs-4 me-3 text-primary fw-bold" />Computer literacy</p>
                        </div>
                        <div className="d-flex align-items-center">
                            <p style={{ fontSize: "14px" }} className="mb-1 fw-medium fst-italic"><BsArrowRight className="fs-4 me-3 text-primary fw-bold" />Logical method of reasoning </p>
                        </div>
                        <div className="d-flex align-items-center">
                            <p style={{ fontSize: "14px" }} className="mb-1 fw-medium fst-italic"><BsArrowRight className="fs-4 me-3 text-primary fw-bold" />Well-developed interpersonal skills</p>
                        </div>
                        <div className="d-flex align-items-center">
                            <p style={{ fontSize: "14px" }} className="mb-1 fw-medium fst-italic"><BsArrowRight className="fs-4 me-3 text-primary fw-bold" />Communicate fluently and clearly in English and French</p>
                        </div>
                        <div className="d-flex align-items-center">
                            <p style={{ fontSize: "14px" }} className="mb-1 fw-medium fst-italic"><BsArrowRight className="fs-4 me-3 text-primary fw-bold" />Broad and ordered knowledge in an area of academic discipline </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OurMision