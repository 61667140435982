import React, { useState } from 'react'

// email service imports
import { useForm } from '@formspree/react'

//react icons import
import { TiTick } from "react-icons/ti"
import { useSelector } from 'react-redux';
// import { HiOutlineMail } from "react-icons/hi"
// import { FaWhatsapp } from "react-icons/fa"
// import { AiFillPhone } from "react-icons/ai"
// import { MdLocationOn } from "react-icons/md"
const Contact = () => {
    const [state, handleSubmit] = useForm("xjvqovab");
    const [modalOpen, setModalOpen] = useState(false);
    const admissions = useSelector((state) => state.appData.admissions)

    return (
        <div className="container d-flex flex-column flex-lg-row justify-content-center align-items-center gap-lg-3 mt-5" id="contact">
            {/* contact info section */}
            <div className="col-12 col-lg-6 mb-3 mb-lg-0">
                <div className="text-left">
                    <h1 className="">Start by messaging us about anything</h1>
                    <p className="text-textGray fs-5 fw-medium">We're looking forward for your application. Leave us a message!!!</p>
                    <p className="fst-italic ">--- Here Are the minimum <b>Admission Requirements.</b></p>
                </div>
                <div className="d-flex flex-wrap flex-lg-column align-items-lg-start justify-content-lg-center gap-1">
                    {
                        admissions.map((admission, i) => {
                            return (
                                <div key={i} style={{backgroundColor: `${admission.color}`}} className="rounded-pill d-flex justify-content-center align-items-center p-2 px-3 border border-dark">
                                    <p className="text-black text-capitalize m-0 fw-medium">{admission.name}</p>
                                </div>
                            )
                        })
                    }
                </div>
            </div>

            {/* Message form */}
            <div className="col-lg-6 z-n1">
                <div className="card card-body p-4 p-sm-5 box-shadow1 border-0">                    
                    <form onSubmit={handleSubmit} className="row g-3">
                        <div className="col-12">
                            <label htmlFor="fullName" className="form-label text-textGray fs-5">Full name *</label>
                            <input style={{ height: "50px" }} type="text" name="full name" id="fullName" className="form-control shadow-none" aria-label="Full Name" required />
                        </div>
                        <div className="col-12">
                            <label htmlFor="Email" className="form-label text-textGray fs-5">Email *</label>
                            <input style={{ height: "50px" }} type="email" name="Email" id="Email" className="form-control shadow-none" aria-label="Email" required />
                        </div>
                        <div className="col-12">
                            <label htmlFor="PhoneNumber" className="form-label text-textGray fs-5">Phone number *</label>
                            <input style={{ height: "50px" }} type="number" name="phone number" id="PhoneNumber" className="form-control shadow-none" aria-label="Phone number" required />
                        </div>
                        <div className="col-12">
                            <label htmlFor="comment" className="form-label text-textGray fs-5">Message *</label>
                            <textarea style={{ height: "120px" }} name="message" id="comment" className="form-control shadow-none" aria-label="comment" required></textarea>
                        </div>
                        <div className="col-12 text-center">
                            <button
                                onClick={() => {
                                    setModalOpen(true);
                                }}
                                type="submit"
                                disabled={state.submitting}
                                className="btn btn-primary mb-0 rounded-pill fw-semibold fs-4 px-4"
                            >
                                Apply
                            </button>
                            {
                                modalOpen && <div
                                    className="d-flex fixed-top h-100 w-100 justify-content-center align-items-center z-2"
                                >
                                    <div className="shadow-lg border-1 rounded-3 px-5 py-4 bg-white">
                                        <TiTick style={{ fontSize: "80px" }} className="text-success" />
                                        <h2 className="text-textGray">Application received.</h2>
                                        <button
                                            className="btn btn-danger"
                                            onClick={() => {
                                                setModalOpen(false)
                                            }}
                                        >
                                            OK
                                        </button>
                                    </div>
                                </div>
                            }
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default Contact