import { useEffect } from 'react';
import { brainslogo } from '../assets/assets';
import Navlinks from './Navlinks';

// Bootstrap imports
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

// translation imports
import { useTranslation } from 'react-i18next';
import { HashLink } from 'react-router-hash-link';


function Header({ language }) {
    const { t, i18n } = useTranslation();

    useEffect(() => {
        i18n.changeLanguage(language);
    }, [language, i18n])

    return (
        <>

            <Navbar collapseOnSelect={true} expand="lg" className="bg-body-tertiary">
                <Container>
                    <Navbar.Brand href="#">
                        <img src={brainslogo} alt="" className="" style={{ height: "50px", width: "200px" }} />
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse className="" id="basic-navbar-nav">
                        <Nav className="me-auto">
                            <Navlinks />
                        </Nav>
                        <div className="">
                            <HashLink to="/#contact">
                                <button
                                    type="button" className="btn bg-primary fw-bold text-white px-4 py-1 rounded-pill fs-6"
                                >
                                    {t('landingPage.header.apply')}
                                </button>
                            </HashLink>
                        </div>
                    </Navbar.Collapse>
                </Container>
            </Navbar>

        </>
    );
}

export default Header;