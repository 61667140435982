import { createSlice } from "@reduxjs/toolkit";
import { drivingClasses, freeWifi, germanlanguage, internship, makeupClasses, scholarship, signlanguage, teachers,  medecineFaculty, hotelFaculty, artsFaculty, agricultureFaculty, businessFaculty, univbamenda, univdouala, univdschang, student9 } from "../../assets/assets";

const initialState = {
    studentBenefits: [
        {
            title: "Scholarship",
            icon: scholarship
        },
        {
            title: "Qualified Teachers",
            icon: teachers
        },
        {
            title: "Free Wifi",
            icon: freeWifi
        },
        {
            title: "Internship Offered",
            icon: internship
        },
        {
            title: "American Sign Language",
            icon: signlanguage
        },
        {
            title: "German Language",
            icon: germanlanguage
        },
        {
            title: "Free Driving Classes",
            icon: drivingClasses
        },
        {
            title: "Free Make-up Classes",
            icon: makeupClasses
        }
    ],
    
    faculties: [
        {
            name: "Health Sciences",
            courses: "Nursing&Physiotherapy&Dental therapy&Nutrition and Dietetics&Pharmacy technology&Medical Lab Sciences&Medical imaging technology&Health care management",
            image: medecineFaculty,
            bgColor: "#F5FCFF"
        },
        {
            name: "Engineering & Technology",
            courses: "Graphic designs &Civil engineering &Electrical engineering &Mechanical engineering &Chemical engineering &Thermal and energy engineering &Network & security &Telecommunication &Software engineering &Computer engineering &Data base management &Hardware maintenance &Computer science & networks &Computer graphics & web design &E-commerce & Digital marketing &Media photography/ Audio visual",
            image: student9,
            bgColor: "#FFF0F3"
        },
        {
            name: "Agriculture & Food Sciences",
            courses: "Aquaculture&Crop production&Food technology&Animal production&Fisheries Management&Agro Pastoral entrepreneurship&Agro pastoral entrepreneurship&Agricultural Business&Agricultural Engineering&Agricultural Production technology",
            image: agricultureFaculty,
            bgColor: "#ECFADC"
        },
        {
            name: "School of Business Management",
            courses: "Insurance&Accountancy&Banking and Finance&Business Law&Customs and Transit&Assistant manager&Events and Quality Management&Investment Trade&Project management&Marketing - trade - sale&Corporate communication&Post shipping management&Human Resource Management&Information Systems management&Logistics and transport management",
            image: businessFaculty,
            bgColor: "#FCE5FC"
        },
        {
            name: "Faculty Of Arts And Design",
            courses: "Fashion design& Product design& Hairdressing professions& Fashion, clothing and textiles& Beauty-Esthetics-Cosmetics",
            image: businessFaculty,
            bgColor: "#FFF0F3"
        },
        {
            name: "Hotel Management & Tourism",
            courses: "Tourism and travel agency management&Hotel management and catering&Bakery and Food processing",
            image: hotelFaculty,
            bgColor: "#EEEEEE"
        }
    ],

    partners: [
        {
            name: "University Of Bamenda",
            image: univbamenda
        },
        {
            name: "University Of Douala",
            image: univdouala
        },
        {
            name: "University Of Dschang",
            image: univdschang
        }
    ],

    admissions: [
        {
            name: "An A4 Envelope",
            color: "#48C4E4"
        },
        {
            name: "Photocopy of GCE Slips",
            color: "#ACD8A7"
        },
        {
            name: "04 Passport Size Photos",
            color: "#FDC2E4"
        },        
        {
            name: "Photocopy of Birth Certificate",
            color: "#FDC2E4"
        },
        {
            name: "02 Reasons  of 80grams A4 paper",
            color: "#DA8EE7"
        },
        {
            name: "Completely filled Admission Form",
            color: "#DED4D4"
        }
    ]
};

export const appDataSlice = createSlice({
    name: "appData",
    initialState,
    reducers: []
})

export default appDataSlice.reducer