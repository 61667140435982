import React from 'react'
import { ourValues } from '../../constants/constants';
import Carousel from 'react-multi-carousel';
import { ourValuesPng } from '../../assets/assets';

const OurValues = () => {
    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3,
            slidesToSlide: 1
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2,
            slidesToSlide: 1
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
            slidesToSlide: 1
        }
    };

    return (
        <div className="my-5 py-2 bg-light position-relative">
            {/* SVG Image */}
            <figure className="position-absolute start-0 bottom-0 mb-0">
                <img src={ourValuesPng} className="h-200px" alt="" />
            </figure>
            <div className="w-100 text-center my-3">
                <h1 className="fw-medium display-5">Our Values</h1>
                <p className="text-textGray fs-5">Our values are core for our existence</p>
            </div>
            <div className="container">
                <Carousel
                    className="py-3 px-2"
                    responsive={responsive}
                    infinite={true}
                    autoPlay={true}
                    autoPlaySpeed={5000}
                    renderButtonGroupOutside={true}
                >
                    {
                        ourValues.map((value, i) => {
                            return (
                                <div key={i} className="p-3 bg-white shadow-sm rounded-3 me-3">
                                    <h3 className="w-100 text-center fs-5 fst-italic">{value.title}</h3>
                                    <ul>
                                        {
                                            value.description.map((desc, i) => {
                                                return (
                                                    <li key={i}>{desc}</li>
                                                )
                                            })
                                        }
                                    </ul>
                                </div>
                            )
                        })
                    }
                </Carousel>
            </div>
        </div>
    )
}

export default OurValues