import React from 'react'
import Hero from '../components/about/Hero'
import OurMision from '../components/about/OurMision'
import OurValues from '../components/about/OurValues'
import FocusObjectives from '../components/about/FocusObjectives'
import OurVision from '../components/about/OurVision'

const About = () => {
    return (
        <div className="w-100 p-0">
            <Hero />
            <OurMision />
            <OurValues />
            <FocusObjectives />
            <OurVision />
        </div>
    )
}

export default About