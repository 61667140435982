import { Nav } from "react-bootstrap"
import { links } from "../constants/constants"
import { HashLink } from "react-router-hash-link"
import { useTranslation } from "react-i18next"

const Navlinks = () => {
    const { t } = useTranslation();

    return (
        <Nav className="justify-content-center flex-grow-1 gap-2 mb-3 mb-md-0">
            {
                links.map((link) => {
                    const translationKey = `landingPage.header.links.${link.name}`;
                    const translatedText = t(translationKey);

                    return <HashLink
                        key={link.name}
                        to={link.to}
                        // style={({ isActive }) => isActive ? {
                        //     color: "white",
                        //     backgroundColor: "blue",
                        // } : {}}
                        className={`px-3 py-2 text-black fw-bold text-decoration-none rounded-pill`}
                    // activeStyle={{
                    //     color: "white",
                    //     backgroundColor: "blue",
                    // }}                        
                    >
                        {translatedText}
                    </HashLink>
                })
            }
        </Nav>
    )
}

export default Navlinks