import React from 'react';
import { student2, student8 } from '../../assets/assets';
import Carousel from 'nuka-carousel'
import { MdNavigateBefore, MdNavigateNext } from 'react-icons/md'
import { useSelector } from 'react-redux';

const StudentBenefits = () => {
    const benefits = useSelector((state) => state.appData.studentBenefits);

    const teamIMGs = [student8, student2];
    const params = {
        renderCenterLeftControls: ({ previousSlide }) => (
            <button onClick={previousSlide}>
                <MdNavigateBefore className="leftRightBtn" />
            </button>
        ),
        renderCenterRightControls: ({ nextSlide }) => (
            <button onClick={nextSlide}>
                <MdNavigateNext className="leftRightBtn" />
            </button>
        ),
        wrapAround: true,
        autoplay: true,
        autoplayInterval: 5000
    }

    return (
        <div className="w-100 container row align-items-center gap-4 gap-lg-0 my-5 py-5" id="ourMission">
            <div className="col-12 col-md-6">
                <h1 className="display-3 fw-medium">Student Benefits</h1>
                <p className="mb-2">
                    We Offer a variety of <b>Benefits</b> To our students as listed below:
                </p>
                <div className="w-100 d-flex flex-wrap gap-1">
                    {
                        benefits.map((benefit, i) => {
                            return (
                                <div key={i} className="w-auto d-flex justify-content-center align-items-center gap-2 p-2 rounded-2 bg-lightGray">
                                    <img src={benefit.icon} className="rounded-pill" style={{height: "50px", width: "50px"}} alt={benefit.title} />
                                    <p className="fs-6 m-0 fw-bold">{benefit.title}</p>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
            <div className="col-12 col-md-6 team-carousel">
                <Carousel {...params} className="h-100 w-100" style={{ borderRadius: "20px" }}>
                    {
                        teamIMGs.map((img, i) => {
                            return (
                                <img
                                    key={i}
                                    src={img}
                                    alt="brains-team"
                                    className="h-100  w-100"
                                />
                            )
                        })
                    }
                </Carousel>
            </div>
        </div>
    )
}

export default StudentBenefits;