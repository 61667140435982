import React, { useState } from 'react'
import { Route, Routes } from 'react-router-dom';
import { Home, About } from "./pages/pages";
import Header from './components/Header';
import Footer from './components/Footer';
import "./styles/custom.scss"

const App = () => {
    const [language, setLanguage] = useState();

    return (
        <div className="w-100 h-100">
            <Header language={language} />

            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/about" element={<About />} />
            </Routes>

            <Footer language={language} setLanguage={setLanguage} />
        </div>
    )
}

export default App