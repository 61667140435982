import React from 'react'
import { staff1, staff2, staff3 } from '../../assets/assets';
import Carousel from 'nuka-carousel'
import { Link } from 'react-router-dom'
import { MdNavigateBefore, MdNavigateNext } from 'react-icons/md'

const OurTeam = () => {
    const teamIMGs = [staff1, staff2, staff3];
    const params = {
        renderCenterLeftControls: ({ previousSlide }) => (
            <button onClick={previousSlide}>
                <MdNavigateBefore className="leftRightBtn" />
            </button>
        ),
        renderCenterRightControls: ({ nextSlide }) => (
            <button onClick={nextSlide}>
                <MdNavigateNext className="leftRightBtn" />
            </button>
        ),
        wrapAround: true,
        autoplay: true,
        autoplayInterval: 5000
    }
    return (
        <div className="w-100 container row align-items-center my-5">
            <div className="col-12 col-md-6 team-carousel">
                <Carousel {...params} className="h-100 w-100" style={{ borderRadius: "20px" }}>
                    {
                        teamIMGs.map((img, i) => {
                            return (
                                <img
                                    key={i}
                                    src={img}
                                    alt="brains-team"
                                    className="h-100  w-100"
                                />
                            )
                        })
                    }
                </Carousel>
            </div>
            <div style={{ paddingLeft: "200px" }} className="col-12 col-md-6 px-4 ps-md-5">
                <h1 className="display-5 fw-medium">Our Team</h1>
                <p className="mb-2">
                    The school boasts a cadre of highly qualified and experienced professionals, reflecting the institution's commitment to excellence. The efficiency of the staff is evident in the seamless operations that contribute to a conducive learning environment. The teachers, possessing a wealth of expertise, deliver top-notch education, ensuring students receive the highest quality of teaching.
                </p>
                <p className="mb-2">
                    This combination of skilled professionals fosters a dynamic educational setting, where students benefit from the rich experience and knowledge imparted by the dedicated staff.
                </p>
                <p className="mb-2 fst-italic fw-medium">
                    👉  innovation, professionalism entrepreneurship and excellence
                </p>
                <div className="d-flex gap-2 mt-4">
                    <Link to="/about" className="btn bg-light text-textGray fw-bold">Contact</Link>
                    <Link to="/about" className="btn bg-superLightBlue text-primary fw-bold">Our Vision</Link>
                </div>
            </div>
        </div>
    )
}

export default OurTeam