import { schoolIMG1, schoolIMG2, tmn1, tmn2, tmn3 } from "../assets/assets";

const links = [
    { name: "Home", to: "/" },
    { name: "About", to: "/#about" },
    { name: "Programs", to: "/#programs" },
    { name: "Faculties", to: "/#faculties" },
    { name: "Contact", to: "/#contact" }
]

const footerLinks = {
    links: [
        { name: "Home", to: "/" },
        { name: "About", to: "/#about" },
        { name: "Programs", to: "/#programs" },
        { name: "Faculties", to: "/#faculties" },
        { name: "Contact", to: "/#contact" }
    ],

    schools: [
        { name: "Health Sciences", to: "/" },
        { name: "Engineering", to: "/" },
        { name: "Agriculture", to: "/" },
        { name: "Business Management", to: "/" },
        { name: "Arts and Design", to: "/" },
        { name: "Hotel Management", to: "/" },
    ]
}

// Landing page

const testimonials = [
    {
        image: tmn1,
        name: "Ange Daniel",
        quote: "At TBU, I have amazing opportunities on internship, networking and hand-work experiences."
    },
    {
        image: tmn2,
        name: "Prosper Che",
        quote: "TBU has given me the mentality of becoming a job creator and not a job seeker."
    },
    {
        image: tmn3,
        name: "NWESE Kay-Joel",
        quote: "My experience at TBU has been transformative. I gained invaluable knowledge, practical skills and a network of inspiring peers and mentors"
    },
    {
        image: tmn2,
        name: "Djambou Vanelle",
        quote: "The Brains, Mon combat… ici ces l’esprit d’entreprenariat et innovation qui est valorise le plus."
    },
    {
        image: tmn1,
        name: "MEGNE KUATE Jessica",
        quote: "I enjoy the additional practical skills taught at TBU. Indeed, TBU prepares us for something more than a career - life."
    }
];

const schools = [
    {
        image: schoolIMG1,
        name: "School Of Health Sciences (SHS)",
        location: "Immeuble Auto22 BP CITE",
        diplomas: ["OND", "DQP", "CQP", "AQP"],
        programs: [
            "Medical delegate",
            "Nursing Assistant",
            "Medical Lab Assistant",
            "Pharmacy salesperson",
            "Physiotherapy Assistant",
            "Midwifery",
            "More"
        ]
    },
    {
        image: schoolIMG2,
        name: "School Of Agriculture And Food Technology",
        location: "Bonaberi - Douala",
        diplomas: ["HND"],
        programs: [
            "Aquaculture",
            "Crop-Production",
            "Animal Production",
            "Agro pastoral adviser",
            "Nutrition And Dietics",
            "Agricultural Engineering",
            "More"
        ]
    }
]

// About page

const ourValues = [
    {
        title: "Innovation and Creativity",
        description: [
            "We seek to stimulate the minds of students and render them more inquisitive and reflective.",
            "We provide a supportive environment to try new things foster creativity in diversity which will lead to entrepreneurship"
        ]
    },
    {
        title: "Entrepreneurship",
        description: [
            "We promote and develop our students ability to create social, cultural and economic value",
            "We provide hands-on assistance for their establishment and success."
        ]
    },
    {
        title: "Excellence",
        description: [
            "At The Brains, we believe that excellence should be your below average.",
            "We encourage the pursuit of the highest levels of academic, civic and personal achievement.",
            "We seek to foster a culture of intellectual and personal growth and lifelong learning"
        ]
    },
    {
        title: "Humanism",
        description: [
            "Our teaching approach is student-centered. This implies that students are at the center of their education.",
            "We seek to foster a compassionate and caring institutional culture.",
            "We respect the dignity of others.",
            "We recognize our mutual interdependence.",
            "We promote socially conscious and responsible citizenship"
        ]
    },
    {
        title: "Rigour",
        description: [
            "We Set expectations for each student to learn at a high level.",
            "We Offer support to each student so he or she can learn at a high level",
            "We Allow each student to demonstrate high-level learning."
        ]
    },
    {
        title: "Professionalism and efficiency",
        description: [
            "We uphold standards of clear communication, responsibility, and respect",
            "We seek to foster the development of excellent interpersonal skills among our students and staff",
            "We Allow each student to demonstrate high-level learning."
        ]
    },
    {
        title: "Respect for Diversity",
        description: [
            "We reflect and serve diverse local, national and international communities.",
            "We create and promote an environment in which diversity is appreciated, respected and celebrated.",
            "oWe foster a culture that welcomes and respects diverse identities, heritages and life experiences."
        ]
    },
    {
        title: "Integrity",
        description: [
            "We commit ourselves to the highest standards of personal honesty and exemplary moral character",
            "We are dedicated to cultivating an atmosphere of trust and respect.",
            "We take responsibility for our decisions, behaviors, actions and the consequences thereof.",
            "We ensure the integrity of our policies, information, systems and processes."
        ]
    },
    {
        title: "Social Justice and Equality",
        description: [
            "We are dedicated to the realization of a socially just, democratic society that promotes equality for all irrespective of race, gender, sex, pregnancy, marital status, ethnic or social origin, age, physical and learning abilities, national origins, religion, conscience, belief, culture and language."
        ]
    },
    {
        title: "Sustainable Stewardship",
        description: [
            "We are committed to environmental sustainability and recognize our responsibility to conserve, protect and sustainably manage natural resources for current and future generations.",
            "We inspire students and employees to embrace responsible stewardship of all financial, human, infrastructural and environmental resources entrusted to them."
        ]
    },
    {
        title: "Ethical Practice",
        description: [
            "We honor the beliefs, morals, and values of the academic profession and help others to do the same"
        ]
    }
]

export {
    links,
    footerLinks,
    testimonials,
    ourValues,
    schools
};