import React from 'react'

const ourVision = () => {
    return (
        <div className="container bg-light rounded-5 p-5 d-flex justify-content-center align-items-center position-relative overflow-hidden" id="ourVision">
            <figure style={{ fill: "#f9ddb1" }} className="position-absolute top-0 start-0 d-none d-lg-block ms-n5">
                <svg width="294.5px" height="261.6px" viewBox="0 0 294.5 261.6">
                    <path className="fill-warning opacity-5" d="M280.7,84.9c-4.6-9.5-10.1-18.6-16.4-27.2c-18.4-25.2-44.9-45.3-76-54.2c-31.7-9.1-67.7-0.2-93.1,21.6 C82,36.4,71.9,50.6,65.4,66.3c-4.6,11.1-9.5,22.3-17.2,31.8c-6.8,8.3-15.6,15-22.8,23C10.4,137.6-0.1,157.2,0,179 c0.1,28,11.4,64.6,40.4,76.7c23.9,10,50.7-3.1,75.4-4.7c23.1-1.5,43.1,10.4,65.5,10.6c53.4,0.6,97.8-42,109.7-90.4 C298.5,140.9,293.4,111.5,280.7,84.9z"></path>
                </svg>
            </figure>
            <div className="text-center z-1">
                <p className="fs-4 mb-3">Our Vision</p>
                <h1 style={{ fontSize: "25px" }} className="">To be a dynamic university recognized for its leadership role in generating professionals who are leaders, innovators and job-creators.</h1>
            </div>
            <figure style={{ fill: "#81d8d0" }} className="position-absolute top-0 end-0 mt-5 me-n5 d-none d-sm-block">
                <svg width="285px" height="272px">
                    <path className="fill-info opacity-4" d="M142.500,-0.000 C221.200,-0.000 285.000,60.889 285.000,136.000 C285.000,211.111 221.200,272.000 142.500,272.000 C63.799,272.000 -0.000,211.111 -0.000,136.000 C-0.000,60.889 63.799,-0.000 142.500,-0.000 Z"></path>
                </svg>
            </figure>
        </div>
    )
}

export default ourVision;