import React from 'react'

import { student5 } from '../../assets/assets'

const PeopleCount = () => {
    return (
        <section className="container py-5 mt-sm-5">
            <div className="">
                <div className="row">
                    <div className="col-12 position-relative z-index-1">
                        {/* <!-- Image --> */}
                        <div className="d-none d-lg-block position-absolute bottom-0 start-0 ms-5 z-2">
                            <img src={student5} style={{height: "300px"}} alt="" />
                        </div>
                        <div className="bg-warning p-4 p-sm-5 rounded position-relative z-index-n1 overflow-hidden">
                            {/* <!-- SVG decoration --> */}
                            <figure className="position-absolute top-50 end-0 translate-middle-y me-n7">
                                <svg style={{ fill: "orange" }} className="fill-orange rotate-193 opacity-2" width="676px" height="161.3px" viewBox="0 0 676 161.3" space="preserve">
                                    <path d="M53.6,18.8c28.6,8.8,50.3,27.3,70.9,48c19.9,19.9,39.5,40.8,65.3,53c53.3,24.9,116,12.4,168.2-9.1 c58.4-23.9,113.2-59.8,176.2-70.3c30.9-5.1,64.1-2.6,90.9,14.7c22.4,14.4,34.4,36.9,39.5,62.4c2.9,14.5,3.9,29.2,4.6,43.9h6.8 c-0.2-4.2-0.5-8.3-0.8-12.5c-1.7-24.1-4.9-49.1-17.6-70.3c-14.5-23.9-40-39.2-67-44.8c-32.9-6.8-67.2-0.3-98.5,10.2 c-30.3,10-59,24.2-87.7,38.1c-54.8,26.4-115.5,53.1-177.9,42c-14.5-2.6-28.7-7.4-41.7-14.7c-12.8-7.3-23.9-16.7-34.6-26.7 c-20.7-19.6-39.4-42-64.1-56.8c-25.6-15.4-56.4-22.2-86-19H0v6.9C17.9,11.8,36.3,13.5,53.6,18.8z"></path>
                                </svg>
                            </figure>

                            <div className="row align-items-end justify-content-end position-relative">
                                <div className="col-lg-10">
                                    <div className="row g-3 d-flex align-items-center justify-content-center">

                                        {/* <!-- Counter item --> */}
                                        <div className="col-sm-6 col-lg-3 text-center">
                                            <div className="d-flex justify-content-center">
                                                <h4 className="purecounter display-6 text-white fw-bold mb-0" data-purecounter-start="0" data-purecounter-end="89" data-purecounter-delay="200" data-purecounter-duration="0">201</h4>
                                            </div>
                                            <h6 className="text-dark mb-0 fw-bold">Total Instructors</h6>
                                        </div>

                                        {/* <!-- Counter item --> */}
                                        <div className="col-sm-6 col-lg-3 text-center">
                                            <div className="d-flex justify-content-center">
                                                <h4 className="purecounter display-6 text-white fw-bold mb-0" data-purecounter-start="0" data-purecounter-end="12" data-purecounter-delay="200" data-purecounter-duration="0">5</h4>
                                            </div>
                                            <h6 className="text-dark mb-0 fw-bold">Total Campuses</h6>
                                        </div>

                                        {/* <!-- Counter item --> */}
                                        <div className="col-sm-6 col-lg-3 text-center">
                                            <div className="d-flex justify-content-center">
                                                <h4 className="purecounter display-6 text-white fw-bold mb-0" data-purecounter-start="0" data-purecounter-end="180" data-purecounter-delay="200" data-purecounter-duration="0">1</h4>
                                                <span className="display-6 text-white mb-0 fw-bold">K</span>
                                            </div>
                                            <h6 className="text-dark mb-0 fw-bold">Students till date</h6>
                                        </div>

                                        {/* <!-- Counter item --> */}
                                        <div className="col-sm-6 col-lg-3 text-center">
                                            <div className="d-flex justify-content-center">
                                                <h4 className="purecounter display-6 text-white fw-bold mb-0" data-purecounter-start="0" data-purecounter-end="23" data-purecounter-delay="200" data-purecounter-duration="0">50</h4>
                                                <span className="display-6 text-white mb-0 fw-bold">+</span>
                                            </div>
                                            <h6 className="text-dark mb-0 fw-bold">Types of Courses</h6>
                                        </div>
                                    </div>
                                </div>
                                {/* </div> <!-- Row END --> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default PeopleCount